import React from 'react';
import { graphql, Link } from 'gatsby';
import { get } from 'lodash';
import styled, { ThemeProvider } from 'styled-components';
import { GlobalStyle, above } from '../styles';
import theme from '../styles/abstracts/theme';
import { Container, CTALink, FluidImg } from '../elements';
import SEO from '../components/SEO';
import '../styles/fonts.css';

const SContainer = styled(Container)`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Content = styled.main`
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 100vh;
`;

const Heading = styled.h1`
  font-weight: bold;
  font-size: 4.2rem;
  line-height: 1;
  margin: 0.2em 0 0.2em 0 !important;
  color: ${props => props.headingcolor.hex};

  @media ${above.md} {
    font-size: 7.5rem;
  }
`;

const Subheading = styled.h2`
  text-align: center;
  font-size: 2.4rem;
  line-height: 1;
  font-weight: normal;
  color: ${props => props.subheadingcolor.hex};
  margin: 0em 0em 40px 0em !important;

  @media ${above.md} {
    font-size: 3.6rem;
  }
`;

const ImgWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  min-height: 100vh;
  width: 100%;
`;

const Logo = styled(Link)`
  width: 100px;
`;

const StyledImage = styled.div`
  background-image: url(${props => props.image});
  background-size: cover;
  overflow: hidden;
  min-height: 100vh;
  width: 100%;
`;

/* 404 page on Sanity with title, SEO description and slug passes data
to the component*/

const NotFoundPage = ({ data }) => {
  const {
    heading,
    headingColor,
    subheading,
    subheadingColor,
    companyLogo,
    ctaLink,
    ctaText,
    bgimage,
    seoTitle,
    seoDescription,
    seoKeywords,
    author,
  } = data.page;

  const seo = {
    title: seoTitle || '',
    seoDescription: seoDescription || '',
    seoKeywords: seoKeywords || '',
    seoTwitterHandle: get(author, 'twitter', '@thejibe'),
    image: get(bgimage, 'asset.fluid.src', ''),
  };

  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <SEO seo={seo} />
        <Content>
          <ImgWrapper>
            {bgimage.asset._ref ? (
              <FluidImg
                asset={bgimage.asset}
                alt={bgimage.alt}
                wrapperStyle={{ height: '100vh' }}
              />
            ) : (
              <StyledImage image={bgimage.asset.fluid.src} />
            )}
          </ImgWrapper>
          <SContainer>
            <Logo href={get(ctaLink[0], 'slug.current', '/')}>
              {companyLogo.asset._ref ? (
                <FluidImg asset={companyLogo.asset} alt={companyLogo.alt} />
              ) : (
                <img
                  src={companyLogo.asset.fluid.src}
                  alt={companyLogo.alt}
                  width="100%"
                />
              )}
            </Logo>
            <Heading headingcolor={headingColor}>{heading}</Heading>
            <Subheading subheadingcolor={subheadingColor}>
              {subheading}
            </Subheading>
            <CTALink
              url={get(ctaLink[0], 'slug.current', '/')}
              text={ctaText}
            />
          </SContainer>
        </Content>
      </>
    </ThemeProvider>
  );
};

export const query = graphql`
  query ErrorPageQuery {
    siteSetting: sanitySiteSettings {
      siteUrl
    }
    page: sanityPage404 {
      seoTitle
      seoDescription
      seoKeywords
      ctaLink {
        slug {
          current
        }
      }
      ctaText
      subheading
      subheadingColor {
        hex
      }
      headingColor {
        hex
      }
      heading
      companyLogo {
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
      bgimage {
        alt
        asset {
          fluid {
            ...GatsbySanityImageFluid_noBase64
          }
        }
      }
    }
  }
`;

export default NotFoundPage;
